import React from 'react';

const Projects = () => {
  const projects = [
    {
      id: 1,
      title: 'AntOS',
      description: 'An operating system I created to learn OSDEV.',
      links: [
          { title: 'View on GitHub', url: 'https://github.com/ant-os' },
          { title: 'Website', url: 'https://antos.proton-dev.net' },
      ],
    },
    {
      id: 2,
      title: 'Emulator',
      description: 'A 8-BIT Emuator for the 6402.',
      links: [
        { title: 'GitHub Repository', url: 'https://github.com/joschatom/Emulator' },
      ],
    },
    // Add more projects here
  ];

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen">
      <h2 className="text-4xl font-bold mb-8 text-center pt-8">Projects</h2>
      <div className="pl-16 pr-16 grid grid-cols-2 gap-5">
        {projects.map((project) => (
          <div key={project.id} className="bg-gray-800 p-8 rounded shadow-lg">
            <h3 className="text-2xl font-semibold mb-4">{project.title}</h3>
            <p className="text-gray-300 mb-4">{project.description}</p>
            {project.links && (
              <ul>
                {project.links.map((link, index) => (
                  <li key={index}>
                    <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-blue-300 hover:text-blue-400 hover:underline">
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
