import React from 'react';

const Donation = () => {
  const patreonURL = 'https://www.patreon.com/user?u=82546816';

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen flex items-center justify-center pb-16">
      <div className="container mx-auto py-12">
        <h2 className="text-4xl font-bold mb-8 text-center">Donate</h2>
        <div className="max-w-lg mx-auto mb-8">
          <p className="text-xl text-center">
            Your support is greatly appreciated. If you find value in the work I do, please consider making a donation to help me continue providing quality content and resources.
          </p>
        </div>
        <div className="flex justify-center">
          <a href={patreonURL} target="_blank" rel="noopener noreferrer" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Donate Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Donation;
