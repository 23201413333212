import React from 'react';

const Header = ({ onInteract }) => {
  const handleLinkClick = (id) => {
    if (onInteract) {
      onInteract(id);
    }
  };

  return (
    <header className="bg-gray-900 py-4">
      <nav className="container mx-auto flex items-center justify-between">
        <a href="/" className="text-white text-2xl font-bold">My Website</a>
        <div className="flex space-x-4 font-bold">
          <button className="text-gray-400 hover:text-gray-300" onClick={() => onInteract(0)}>
            Home
          </button>
          <a href="#" className="text-blue-400 hover:text-blue-300" onClick={() => handleLinkClick(1)}>
            About
          </a>
          <a href="#" className="text-blue-400 hover:text-blue-300" onClick={() => handleLinkClick(2)}>
            Projects
          </a>
          <a href="#" className="text-blue-400 hover:text-blue-300" onClick={() => handleLinkClick(3)}>
            Contact
          </a>
          <a href="#" className="text-green-400 hover:text-green-300" onClick={() => handleLinkClick(4)}>
            Donate
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
