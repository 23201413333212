import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube, faReddit, faGithub, faGitlab } from '@fortawesome/free-brands-svg-icons';
import { Tooltip } from 'react-tooltip';


const Main = () => {
  const backgroundImageUrl = 'url(background.jpg)';

return (
    <div
      className="bg-gray-900 text-white py-16 px-8 flex flex-col items-center justify-center"
      style={{
        backgroundImage: backgroundImageUrl,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: 'calc(100vh - 64px)', // Adjust the value based on your layout
      }}
    >
      <h1 className="text-6xl font-bold mb-8">Welcome to My Personal Website!</h1>
      <p className="text-xl mb-6">
        Hi there! I'm a joung programmer, and this is my personal website where I share information about my projects and interests.
      </p>
      <p className="text-xl mb-6">
        Connect with me on different platforms to stay updated with my latest work and engage in interesting discussions:
      </p>
      <div className="flex space-x-4">
        <a
          href="https://www.youtube.com/channel/UCW0dbyfVcx_Pwi1VaUGoCuw"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-400 hover:text-blue-300"
          data-tip="YouTube"
        >
          <FontAwesomeIcon icon={faYoutube} size="2x" />
        </a>
        <a
          href="https://www.reddit.com/user/Proton_46"
          target="_blank"
          rel="noopener noreferrer"
          className="text-orange-400 hover:text-orange-300"
          data-tip="Reddit"
        >
          <FontAwesomeIcon icon={faReddit} size="2x" />
        </a>
        <a
          href="https://github.com/joschatom"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white hover:text-gray-400"
          data-tip="GitHub"
        >
          <FontAwesomeIcon icon={faGithub} size="2x" />
        </a>
        <a
          href="https://gitlab.com/proton46"
          target="_blank"
          rel="noopener noreferrer"
          className="text-red-400 hover:text-red-300"
          data-tip="GitLab"
        >
          <FontAwesomeIcon icon={faGitlab} size="2x" />
        </a>
      </div>
      <Tooltip effect="solid" place="bottom" />
    </div>
  );
};

export default Main;
