import React from 'react';

const Footer = () => {
  const currentDate = new Date().getFullYear();

  return (
    <footer className="bg-gray-800 py-2 text-sm text-white text-center fixed bottom-0 w-full">
      <div className="container mx-auto flex items-center justify-between">
        <p>&copy; {currentDate} Joscha Egloff. All rights reserved.</p>
        <p>Contact: <a href="mailto:contact@proton-dev.net" className="text-white hover:text-gray-400">contact@proton-dev.net</a></p>
      </div>
    </footer>
  );
};

export default Footer;
