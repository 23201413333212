import React from 'react';

const Contact = () => {
  return (
    <div className="bg-gray-900 text-white min-h-screen flex flex-col justify-center items-center py-16 px-8">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-8">Contact</h1>
        <div className="max-w-lg">
          <div className="mb-8">
            <h2 className="text-2xl font-bold">Website Contact:</h2>
            <a
              href="mailto:contact@proton-dev.net"
              className="text-lg text-blue-400 hover:text-blue-300"
            >
              contact@proton-dev.net
            </a>
          </div>
          <div className="mb-8">
            <h2 className="text-2xl font-bold">Personal Contact:</h2>
            <a
              href="mailto:joscha.egloff@pm.me"
              className="text-lg text-blue-400 hover:text-blue-300"
            >
              joscha.egloff@pm.me
            </a>
          </div>
          <div>
            <h2 className="text-2xl font-bold mb-4">Social Media:</h2>
            <div className="flex space-x-4 justify-center">
              <a
                href="https://www.youtube.com/channel/UCW0dbyfVcx_Pwi1VaUGoCuw"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-300"
              >
                YouTube
              </a>
              <a
                href="https://www.reddit.com/user/Proton_46"
                target="_blank"
                rel="noopener noreferrer"
                className="text-orange-400 hover:text-orange-300"
              >
                Reddit
              </a>
              <a
                href="about:blank"
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-400 hover:text-purple-300"
              >
                Discord
              </a>
              <a
                href="https://github.com/joschatom"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-400"
              >
                GitHub
              </a>
              <a
                href="https://gitlab.com/proton46"
                target="_blank"
                rel="noopener noreferrer"
                className="text-red-400 hover:text-red-300"
              >
                GitLab
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
