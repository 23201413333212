import React from 'react';

const About = () => {
  const name = 'Joscha Egloff';
  const age = 14;

  return (
    <div className="bg-gray-900 text-gray-100 min-h-screen flex items-center justify-center">
      <div className="container mx-auto py-12">
        <h2 className="text-4xl font-bold mb-8 text-center">About</h2>
        <div className="max-w-lg mx-auto">
          <p className="text-xl text-center">
            Hello, my name is {name} and I'm {age} years old. I am a young programmer passionate about building amazing applications and learning new technologies. I love the creativity and problem-solving involved in programming, and I'm always excited to take on new challenges.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
