import { useState} from "react";
import React from 'react';
import "./index.css"
import logo from './logo.svg';
import Header from './Header';
import Main from "./Main";
import Contact from "./Contact";
import Projects from "./Projects";
import Footer from "./Footer";
import About from "./About";
import Donation from "./Donation";

function App() {

    React.useEffect(() => {
    document.documentElement.style.overflow = 'hidden'; // Disable scroll bar
    return () => {
      document.documentElement.style.overflow = ''; // Enable scroll bar on unmount
    };
  }, []);

  const [siteID, SetSideID] = useState(0);

  const handleInteraction = (id) => {
    console.log(id);
    SetSideID(id);
  };

  return (
    <div className="App">
      <Header onInteract={handleInteraction}></Header>
      {siteID === 0 && <Main />}
      {siteID === 1 && <About />}
      {siteID === 2 && <Projects />}
      {siteID === 3 && <Contact />}
      {siteID === 4 && <Donation />}
      <Footer></Footer>
    </div>
  );
}

export default App;
